.container {
  height: calc(100vh - 40px);
  width: 100%;
  border-top: 1px solid #dee2e6;
}

.container > *:first-child {
  width: 60%;
  border-right: 1px solid #dee2e6;
}
.container > *:last-child {
  width: 40%;
}

.contentContainer {
  padding: 24px 1px 24px 24px;
  height: calc(100% - 55px);
  overflow: auto;
}
.addObjectsContainer {
  margin-top: 120px;
}

.addObjectsContainer:hover img {
  opacity: 0.9;
}

.objectsManagerContainer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: white;
  z-index: 1040;
}
