.btn-dark {
  --bs-btn-bg: #6b7aff;
  --bs-btn-border-color: #6b7aff;
  --bs-btn-hover-bg: #8793ff;
  --bs-btn-hover-border-color: #7b85ff;
  --bs-btn-active-bg: #95a0ff;
  --bs-btn-active-border-color: #7b85ff;
  --bs-btn-disabled-bg: #6b7aff;
  --bs-btn-disabled-border-color: #6b7aff;
}

.dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-toggle {
  text-align: start;
}
.dropdown-toggle::after {
  float: right;
  margin-top: 8px;
}

.actions-dropdown {
  display: inline-flex;
}

.mapboxgl-ctrl-group button {
  width: 44px !important;
  height: 44px !important;
  opacity: 0.6;
}

.mapboxgl-ctrl-group button:hover {
  background-color: white !important;
  opacity: 1;
}

.mapboxgl-ctrl-group button:first-child {
  border-radius: 8px 8px 0 0 !important;
}

.mapboxgl-ctrl-group button:last-child {
  border-radius: 0 0 8px 8px !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

blockquote {
  background: #f9f9f9;
  border-left: 3px solid #ccc;
  padding: 0px 10px 10px 10px;
  margin: 0px;
}

blockquote p {
  display: inline;
}

.row {
}

.row .col {
  padding-left: 0px; /* adjust as needed */
  padding-top: 16px;
  padding-bottom: 16px;
}
.row .col:last-child {
  padding-right: 0px; /* adjust as needed */
}

.rbt-close-content {
  display: none;
}

.btn-close.rbt-close {
  margin-top: 0;
}

model-viewer {
  width: 100%;
  height: 100%;
}
